<template>
  <div>
    <div class="flex-r">
      <div>
        <el-date-picker
          class="mr10 mb20"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="截止時間"
          @change="changeTime"
          clearable
        ></el-date-picker>
        <el-button type="primary" @click="search">搜尋</el-button>
        <el-button type="primary" @click="exportData">導出</el-button>
      </div>
    </div>
    <el-table :data="tableData.data" style="width: 100%">
      <el-table-column width="60" label="NO.">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="用戶ID">
        <template slot-scope="scope">{{ scope.row.realname }}</template>
      </el-table-column>
      <el-table-column label="用戶名稱">
        <template slot-scope="scope">{{ scope.row.nickname }}</template>
      </el-table-column>
      <el-table-column label="成交金額（HK$）">
        <template slot-scope="scope">{{ scope.row.price }}</template>
      </el-table-column>
    </el-table>
    <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: [],
      filters: {
        start_time: '',
        end_time: '',
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    changeTime(v) {
      if (Object.prototype.toString.call(v) == '[object Array]') {
        if (v.length > 0) {
          this.filters.start_time = v[0].getTime() / 1000
          this.filters.end_time = v[1].getTime() / 1000
        }
      } else {
        this.filters.start_time = ''
        this.filters.end_time = ''
      }
      // this.search()
    },
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.statistics.getDealMoney({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.statistics.exportDealMoney({
        page: this.tableData.current_page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        var a = document.createElement('a')
        a.href = res.data
        document.body.append(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.c-echart {
  height: 300px;
}
</style>
