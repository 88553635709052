import { render, staticRenderFns } from "./newUser.vue?vue&type=template&id=498e4535&scoped=true"
import script from "./newUser.vue?vue&type=script&lang=js"
export * from "./newUser.vue?vue&type=script&lang=js"
import style0 from "./newUser.vue?vue&type=style&index=0&id=498e4535&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498e4535",
  null
  
)

export default component.exports