<template>
  <div>
    <div class="c-echart">
      <div class="c-cell">
        <div class="flex-r">
          <el-tooltip
            class="item"
            effect="dark"
            content="圖表內容為近7日數據"
            placement="top-start"
          >
            <span class="c-help">i</span>
          </el-tooltip>
        </div>
        <v-chart
          v-if="option"
          style="width: 100%;height: 100%;"
          :options="option"
          :autoresize="true"
        ></v-chart>
      </div>
      <div class="c-cell">
        <div class="flex-r">
          <el-tooltip
            class="item"
            effect="dark"
            content="圖表內容為近7日數據"
            placement="top-start"
          >
            <span class="c-help">i</span>
          </el-tooltip>
        </div>
        <v-chart
          v-if="option2"
          style="width: 100%;height: 100%;"
          :options="option2"
          :autoresize="true"
        ></v-chart>
      </div>
    </div>
    <div class="flex-bt pt20">
      <div class="pt10">
        <span>新增用戶數：{{ st.newuser_num }} </span>
        <span class="ml30">累計用戶數：{{ st.user_num }}</span>
      </div>
      <div>
        <el-date-picker
          class="mr10 mb20"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="截止時間"
          @change="changeTime"
          clearable
        ></el-date-picker>
        <el-button class="mb20" type="primary" @click="search">搜尋</el-button>
        <el-button class="mb20" type="primary" @click="exportData"
          >導出</el-button
        >
      </div>
    </div>
    <el-table :data="tableData.data" style="width: 100%">
      <el-table-column width="60" label="NO.">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="用戶ID">
        <template slot-scope="scope">{{ scope.row.realname }}</template>
      </el-table-column>
      <el-table-column label="用戶名稱">
        <template slot-scope="scope">{{ scope.row.nickname }}</template>
      </el-table-column>
      <el-table-column label="註冊时间">
        <template slot-scope="scope">{{
          scope.row.create_time | formatTime
        }}</template>
      </el-table-column>
    </el-table>
    <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
/** ecahrs */
import ECharts from 'vue-echarts' //
import 'echarts/lib/chart/line'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
export default {
  components: { 'v-chart': ECharts },
  data() {
    return {
      time: [],
      filters: {
        start_time: '',
        end_time: '',
      },
      st: {
        newuser_num: 0,
        user_num: 0,
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      option: {
        tooltip: {
          trigger: 'axis',
        },
        title: {
          left: 'center',
          text: '新增用戶數',
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
          },
        ],
      },
      option2: {
        tooltip: {
          trigger: 'axis',
        },
        title: {
          left: 'center',
          text: '累計用戶數',
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
          },
        ],
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    changeTime(v) {
      if (Object.prototype.toString.call(v) == '[object Array]') {
        if (v.length > 0) {
          this.filters.start_time = v[0].getTime() / 1000
          this.filters.end_time = v[1].getTime() / 1000
        }
      } else {
        this.filters.start_time = ''
        this.filters.end_time = ''
      }
      // this.search()
    },
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.statistics.getNewUser({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data.lists
        this.st = {
          newuser_num: res.data.newuser_num,
          user_num: res.data.user_num,
        }
        if (Object.prototype.toString.call(res.data.sadd) == '[object Array]') {
          let arr = [],
            xData = []
          res.data.sadd.forEach((it) => {
            arr.push(it.total)
            xData.push(it.date)
          })
          this.option.xAxis.data = xData
          this.option.series = [
            {
              data: arr,
              type: 'line',
              smooth: true,
            },
          ]
        }
        if (
          Object.prototype.toString.call(res.data.user_st) == '[object Array]'
        ) {
          let arr = [],
            xData = []
          res.data.user_st.forEach((it) => {
            arr.push(it.num)
            xData.push(it.create_time)
          })
          this.option2.xAxis.data = xData
          this.option2.series = [
            {
              data: arr,
              type: 'line',
              smooth: true,
            },
          ]
        }
      }
      this.loadList = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.statistics.exportNewUser(this.filters)
      if (res && res.code == 0) {
        var a = document.createElement('a')
        a.href = res.data
        document.body.append(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.c-echart {
  height: 300px;
  display: flex;
  justify-content: space-between;
  .c-cell {
    width: 49%;
  }
}
</style>
