<template>
  <div>
    <div class="c-echart">
      <div class="flex-r">
        <el-tooltip
          class="item"
          effect="dark"
          content="圖表內容為平台總數據"
          placement="top-start"
        >
          <span class="c-help">i</span>
        </el-tooltip>
      </div>
      <v-chart
        v-if="option"
        style="width: 100%;height: 100%;"
        :options="option"
        :autoresize="true"
      ></v-chart>
    </div>
    <div class="flex-bt pt20 pb20">
      <div class="pt10">
        <span class="mr15" v-for="it in st" :key="it.id"
          >{{ it.name }}：{{ it.percent }}%</span
        >
      </div>
      <div>
        <el-date-picker
          class="mr10 mb20"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="截止時間"
          @change="changeTime"
          clearable
        ></el-date-picker>
        <el-button type="primary" @click="search">搜尋</el-button>
        <el-button type="primary" @click="exportData">導出</el-button>
      </div>
    </div>
    <el-table :data="tableData.data" style="width: 100%">
      <el-table-column width="60" label="NO.">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="訪問系統">
        <template slot-scope="scope">{{ scope.row.os }}</template>
      </el-table-column>
      <el-table-column label="訪問時間">
        <template slot-scope="scope">{{
          scope.row.create_time | formatTime
        }}</template>
      </el-table-column>
    </el-table>
    <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
/** ecahrs */
import ECharts from 'vue-echarts' //
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import { color } from '../../js/options'
export default {
  components: { 'v-chart': ECharts },
  data() {
    return {
      time: [],
      filters: {
        start_time: '',
        end_time: '',
      },
      st: [],
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      option: {
        title: {
          text: 'OS分佈',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return (
              params.data.name +
              '<br />' +
              '總數：' +
              params.data.value +
              '<br />佔比：' +
              params.data.percent +
              '%'
            )
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        color,
        series: [
          {
            name: 'age',
            type: 'pie',
            radius: '50%',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    changeTime(v) {
      if (Object.prototype.toString.call(v) == '[object Array]') {
        if (v.length > 0) {
          this.filters.start_time = v[0].getTime() / 1000
          this.filters.end_time = v[1].getTime() / 1000
        }
      } else {
        this.filters.start_time = ''
        this.filters.end_time = ''
      }

      // this.search()
    },
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.statistics.getOS({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data.lists
        if (Object.prototype.toString.call(res.data.os) == '[object Array]') {
          let arr = []
          res.data.os.forEach((it) => {
            arr.push({
              id: it.id,
              name: it.os,
              percent: it.percent, //百分比
              value: it.total, //数量
            })
          })
          this.st = arr
          this.option.series = [
            {
              name: 'gender',
              type: 'pie',
              radius: '50%',
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ]
        }
      }
      this.loadList = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.statistics.exportOS({
        page: this.tableData.current_page,
        ...this.filters,
      })
      var a = document.createElement('a')
      a.href = res.data
      document.body.append(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>
<style scoped lang="scss">
.c-echart {
  height: 300px;
}
</style>
