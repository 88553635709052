<template>
  <div ref="main" class="m-main-wrap" v-loading="loading">
    <div ref="topDom"></div>
    <div :style="{ height: tableHeight + 'px' }">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="訪客數" name="tab1">
          <customerVue></customerVue>
        </el-tab-pane>
        <el-tab-pane label="新增用戶數" name="tab2">
          <newUserVue></newUserVue>
        </el-tab-pane>
        <el-tab-pane label="成交買家數" name="tab3">
          <buyerVue></buyerVue>
        </el-tab-pane>
        <el-tab-pane label="成交賣家數" name="tab4">
          <sellerVue></sellerVue>
        </el-tab-pane>
        <el-tab-pane label="性別分佈" name="tab5">
          <genderVue></genderVue>
        </el-tab-pane>
        <el-tab-pane label="年齡分佈" name="tab6">
          <ageVue></ageVue>
        </el-tab-pane>
        <el-tab-pane label="地域分佈" name="tab7">
          <areaVue></areaVue>
        </el-tab-pane>
        <el-tab-pane label="OS分佈" name="tab8">
          <osVue></osVue>
        </el-tab-pane>
        <el-tab-pane label="成交量排行" name="tab9">
          <dealNumVue></dealNumVue>
        </el-tab-pane>
        <el-tab-pane label="成交額排行" name="tab10">
          <dealMoneyVue></dealMoneyVue>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { calConHeight } from '@/common/tool'
import customerVue from './components/customer.vue'
import newUserVue from './components/newUser.vue'
import buyerVue from './components/buyer.vue'
import sellerVue from './components/seller.vue'
import genderVue from './components/gender.vue'
import ageVue from './components/age.vue'
import areaVue from './components/area.vue'
import osVue from './components/os.vue'
import dealNumVue from './components/dealNum.vue'
import dealMoneyVue from './components/dealMoney.vue'
export default {
  components: {
    customerVue,
    newUserVue,
    buyerVue,
    sellerVue,
    genderVue,
    ageVue,
    areaVue,
    osVue,
    dealNumVue,
    dealMoneyVue,
  },
  data() {
    return {
      loading: false,
      tableHeight: 400,
      activeName: 'tab1',
    }
  },
  created() {
    this.activeName = this.$route.query.tab ? this.$route.query.tab : 'tab1'
  },
  mounted() {
    // this.showTime()
    this.$nextTick(() => {
      this.tableHeight = calConHeight(this)
    })
  },
  methods: {
    handleClick(active) {},
    getStDatas() {},
  },
}
</script>
<style scoped>
.m-main-wrap {
  overflow: auto;
  padding-bottom: 100px;
}
</style>
