<template>
  <div>
    <div class="c-echart">
      <div class="flex-r">
        <el-tooltip
          class="item"
          effect="dark"
          content="圖表內容為平台總數據"
          placement="top-start"
        >
          <span class="c-help">i</span>
        </el-tooltip>
      </div>
      <v-chart
        v-if="option"
        style="width: 100%;height: 100%;"
        :options="option"
        :autoresize="true"
      ></v-chart>
    </div>
    <div class="flex-bt pt20 pb20">
      <div class="pt10">
        <span class="mr30" v-for="it in st" :key="it.id"
          ><strong>{{ it.name }}：</strong>{{ it.percent }}%</span
        >
      </div>
      <div>
        <el-select
          class="w200 mr10"
          v-model="filters.type"
          filterable
          placeholder="選擇類型"
          clearable
          @clear="search"
          @keyup.enter.native="search"
        >
          <el-option
            v-for="(item, index) in selects"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="search">搜尋</el-button>
        <el-button type="primary" @click="exportData">導出</el-button>
      </div>
    </div>
    <el-table :data="tableData.data" style="width: 100%">
      <el-table-column width="60" label="NO.">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="用戶ID">
        <template slot-scope="scope">{{ scope.row.realname }}</template>
      </el-table-column>
      <el-table-column label="用戶名稱">
        <template slot-scope="scope">{{ scope.row.nickname }}</template>
      </el-table-column>
      <el-table-column label="性別" prop="sex"></el-table-column>
    </el-table>
    <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
/** ecahrs */
import ECharts from 'vue-echarts' //
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import { color } from '../../js/options'
export default {
  components: { 'v-chart': ECharts },
  data() {
    return {
      // 1.男，2.女，3.非二元 4.不透露 5.其他
      selects: { 1: '男', 2: '女', 3: '非二元', 4: '不透露', 5: '其他' },
      filters: {
        type: '',
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      st: [],
      option: {
        color,
        title: {
          text: '性別分佈',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return (
              params.data.name +
              '<br />' +
              '總數：' +
              params.data.value +
              '<br />佔比：' +
              params.data.percent +
              '%'
            )
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [],
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.statistics.getGender({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data.lists
        if (
          Object.prototype.toString.call(res.data.sexdbt) == '[object Array]'
        ) {
          let arr = []
          res.data.sexdbt.forEach((it) => {
            arr.push({
              id: it.id,
              name: it.name, //性别
              percent: it.percent, //百分比
              value: it.total, //数量
            })
          })
          this.st = arr
          this.option.series = [
            {
              name: 'gender',
              type: 'pie',
              radius: '50%',
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ]
        }
      }
      this.loadList = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.statistics.exportGender(this.filters)
      if (res && res.code == 0) {
        var a = document.createElement('a')
        a.href = res.data
        document.body.append(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.c-echart {
  height: 300px;
}
</style>
